<template>
  <section>
    <vx-card>
      <vs-tabs v-model="clubIndex" :color="colorx">
        <vs-tab
          v-for="(club, index) in clubs"
          :key="club.key"
          :label="club.name"
          :icon-pack="club.iconPack || 'feather'"
          :icon="club.icon"
          :class="[index === 0 ? `${club.key}-club` : null]"
          :uk-position-center-left="index !== 0 ? `${club.key}-club` : null"
          @click.prevent="colorx = club.color"
        >
          <div>
            <vs-collapse type="margin">
              <vs-collapse-item
                v-for="stage in stages"
                :key="stage.id"
                :disabled="
                  outboundPagination(outbounds, club.key, stage.id).total === 0
                "
              >
                <div slot="header" class="font-weight-bold">
                  {{ stage.title }}
                  <span
                    v-if="
                      outboundPagination(outbounds, club.key, stage.id).total
                    "
                    class="badge ml-3 font-weight-bold"
                  >
                    {{
                      outboundPagination(outbounds, club.key, stage.id).total ||
                        0
                    }}
                  </span>
                </div>
                <!-- table -->
                <custom-vs-table
                  v-if="outboundPagination(outbounds, club.key, stage.id).total"
                  pagination
                  stripe
                  sst
                  hoverFlat
                  :data="outboundData(outbounds, club.key, stage.id)"
                  :max-items="
                    outboundPagination(outbounds, club.key, stage.id).per_page
                  "
                  :total="
                    outboundPagination(outbounds, club.key, stage.id).total
                  "
                  @change-page="
                    (page) => onChangePage(club.key, stage.id, page)
                  "
                >
                  <template slot="thead">
                    <vs-th class="thead">No</vs-th>
                    <vs-th class="thead" sort-key="fullName">Name</vs-th>
                    <vs-th class="thead" sort-key="phone">Contact Number</vs-th>
                    <vs-th class="thead" sort-key="status">Current Stage</vs-th>
                    <vs-th class="thead" sort-key="channel"
                      >Contact Channel</vs-th
                    >
                    <vs-th class="thead" sort-key="action">Action</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr v-for="(child, index) in data" :key="index">
                      <vs-td>
                        {{
                          outboundRowNo(outbounds, club.key, stage.id, index)
                        }}
                      </vs-td>
                      <vs-td>
                        {{ lodash.get(child, 'profile.name', '-') }}
                      </vs-td>
                      <vs-td>
                        {{ lodash.get(child, 'profile.phone_number', '-') }}
                      </vs-td>
                      <vs-td>
                        {{ lodash.get(child, 'current_stage', '-') }}
                      </vs-td>
                      <vs-td>
                        <span>
                          {{
                            mergeContactChannel(
                              lodash.get(child, 'profile.contact_channel', '-')
                            )
                          }}
                        </span>
                      </vs-td>
                      <vs-td>
                        <vs-button
                          class="float-left"
                          color="primary"
                          type="filled"
                          icon-pack="feather"
                          icon="icon-message-square"
                          :to="{
                            name: 'outbound.edit',
                            params: {
                              memberId: child.member_id,
                              profileId: child.profile_id,
                            },
                            query: {
                              ['new-case']: true,
                            },
                          }"
                        >
                          New Case
                        </vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </custom-vs-table>
                <!-- table -->
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CustomVsTable from '@/components/table/CustomVsTable'

export default {
  components: {
    CustomVsTable,
  },
  filters: {
    currentStage (value) {
      return value?.current?.title
    },
  },
  data: () => ({
    mounted: false,
    locked: false,
    colorx: 'rgb(23, 71, 158)',
    club: 'blue',
    clubIndex: 0,
  }),
  computed: {
    ...mapGetters('member', ['clubs']),
    ...mapGetters('dashboard', ['stages', 'outbounds']),
  },
  methods: {
    ...mapActions('dashboard', ['fetch', 'fetchClub', 'reset']),

    getClub (club) {
      return this.clubs.find((item) => item.key === club)
    },
    // rowNo (index) {
    //   const current_page = this.lodash.get(this.lists, 'meta.pagination.current_page', 0)
    //   const per_page = this.lodash.get(this.lists, 'meta.pagination.per_page', 1)

    //   return index + 1 + (current_page > 1 ? (current_page - 1) * per_page : 0)
    // },
    mergeContactChannel (contact_channel) {
      if (this.lodash.isArray(contact_channel)) {
        return contact_channel.join(', ')
      }

      return this.lodash.isEmpty(contact_channel) ? '-' : contact_channel
    },

    outboundData (value, club, stage) {
      return value?.[club]?.[stage]?.data || []
    },
    outboundPagination (value, club, stage) {
      return value?.[club]?.[stage]?.meta?.pagination || {}
    },
    outboundRowNo (value, club, stage, index) {
      const pagination = value?.[club]?.[stage]?.meta?.pagination || {
        current_page: 0,
        per_page: 0,
      }

      return (
        index +
        1 +
        // eslint-disable-next-line multiline-ternary
        (pagination.current_page > 1
          // eslint-disable-next-line multiline-ternary
          ? (pagination.current_page - 1) * pagination.per_page
          : 0)
      )
    },
    async fetchByClub ({ club } = {}) {
      if (this.lodash.isEmpty(this.lodash.get(this.outbounds, club))) {
        this.locked = true

        await this.fetchClub({ club })

        const { color } = this.getClub(club)
        this.colorx = color

        this.locked = false
      }
    },
    onChangePage (club, stage, page) {
      this.fetch({ club, stage, page })
    },
  },
  beforeMount () {
    if (this.$route.query.club) {
      const club = this.getClub(this.$route.query.club)
      this.clubIndex = this.lodash.findIndex(this.clubs, { key: club.key })
    }

    if (!this.lodash.isEmpty(this.clubs)) {
      this.club = this.lodash.get(this, `clubs[${this.clubIndex}].key`)
    }
  },
  async mounted () {
    this.fetchByClub({ club: this.club })

    this.mounted = true
  },
  beforeDestroy () {
    this.reset()
  },
  watch: {
    locked (value) {
      if (value === true) {
        this.$vs.loading()
      } else {
        this.$vs.loading.close()
      }
    },
    clubIndex (to) {
      this.club = this.clubs[to].key

      this.$router.push({ query: { club: this.club } }).catch(() => {})

      if (this.mounted) {
        this.fetchByClub({ club: this.club })
      }
    },
  },
}
</script>

<style lang="scss">
.vs-tabs--content,
.vs-collapse {
  padding: 10px 0;
}
.vs-collapse-item {
  background-color: #ffffff;
}
.vs-collapse-item--icon-header {
  .vs-icon {
    font-size: 2rem;
  }
}
</style>
